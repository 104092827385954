"use client";
import Cookies from "js-cookie";
import dynamic from "next/dynamic";
import { useSearchParams } from "next/navigation";
import React, { useEffect, useState } from "react";

import { Container } from "@cgc/ui/layout";
import { Paragraph } from "@cgc/ui/typography";
import { sendGTMEvent } from "@next/third-parties/google";

// import RoktSnippet from "@/components/RoktSnippet";
const RoktSnippet = dynamic(() => import("@/components/RoktSnippet"), {
  ssr: false,
});
// import MentionMe from "@/components/MentionMe";
const MentionMe = dynamic(() => import("@/components/MentionMe"), {
  ssr: false,
});

declare global {
  interface Window {
    // dataLayer: any;
    ire: any;
    pintrk: any;
  }
}

// const getConvertData = () => {
//   try {
//     return JSON.parse(Cookies.get("conv-exps") ?? "");
//   } catch (e) {}
// };

const MembershipConfirmationInfo: React.FC<{ id: string }> = ({ id }) => {
  const searchParams = useSearchParams();
  const frequency =
    searchParams?.get("shipping_interval_frequency") ?? undefined;
  const frequencyString = Number(frequency) === 1 ? "monthly" : "bi-monthly";
  const deliveryDateParam = searchParams?.get("delivery_date") ?? undefined;
  const boxTitleParam = searchParams?.get("box_title") ?? undefined;
  const discountCode = searchParams?.get("discount_code") ?? undefined;
  const totalDiscount = searchParams?.get("total_discount") ?? undefined;
  const emailSha = searchParams?.get("ceh") ?? undefined;
  const sku = searchParams?.get("sku") ?? undefined;
  const orderId = searchParams?.get("order_id") ?? undefined;
  const totalPrice = searchParams?.get("total_price") ?? undefined;
  const firstName = searchParams?.get("fn") ?? undefined;
  const lastName = searchParams?.get("ln") ?? undefined;
  const shopifyCustomerId = searchParams?.get("scid") ?? undefined;
  // const productId = searchParams?.get("pid") ?? undefined;
  const email = (Cookies.get("signup_em") || searchParams?.get("ce")) ?? "";

  const [hasLoaded, setHasLoaded] = useState(false);
  const [boxTitle, setBoxTitle] = useState("");
  const [isTasterBox, setIsTasterBox] = useState(false);
  const [spirit, setSpirit] = useState<"gin" | "rum">(null);
  const [isWelcomeBox, setIsWelcomeBox] = useState(false);
  const [productTitle, setProductTitle] = useState("Gin ff The Month");
  const [deliveryDate, setDeliveryDate] = useState("");

  const [orderInfo, setOrderInfo] = useState({
    boxTitle: "",
    handle: "",
    etd: "",
  });

  useEffect(() => {
    if (orderInfo.handle !== "") {
      return;
    }

    fetch(
      `https://nr-api.craftginclub.co.uk/public/subscription/box/info/${orderId}`,
    )
      .then((response) => response.json())
      .then((data) => {
        const isWelcome =
          data.handle === "gotm_welcome" || data.handle === "rotm_welcome";
        const isRumTaster = data.handle.startsWith("rotm_taster");
        const isGinTaster = data.handle.startsWith("gotm_taster");
        const isRumBox = data.handle === "rotm_standard";

        const isRum = isRumTaster || isRumBox;
        const isGin = isGinTaster || data.handle.startsWith("gotm_");

        setOrderInfo(data);
        setBoxTitle(data.boxTitle || boxTitleParam);
        setIsTasterBox(isGinTaster || isRumTaster);
        setIsWelcomeBox(isWelcome);
        setProductTitle(isRumBox ? "Rum of the Month" : "Gin of the Month");
        setDeliveryDate(data.etd || deliveryDateParam);

        if (isRum) {
          setSpirit("rum");
        }
        if (isGin) {
          setSpirit("gin");
        }

        setHasLoaded(true);
      });
  }, [orderId]);

  const isFreeGin = !!discountCode?.toLowerCase().startsWith("free");

  // logSignUp
  useEffect(() => {
    if (orderId) {
      return;
    }
    // TODO: move behind graphql
    fetch("https://hook.eu1.make.com/oeffx4ke92lkvw6352lblyhc8p61mv0e", {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        step: "confirmation_page",
        orderId,
        details: "",
      }),
    });
  }, [orderId]);

  useEffect(() => {
    // Fetch convert data from cookies and send to BigQuery through Make
    const convertData = Cookies.get("conv-exps");

    if (convertData) {
      const convertTests = JSON.parse(convertData);

      if (Array.isArray(convertTests)) {
        convertTests.forEach((test) => {
          fetch("https://hook.eu1.make.com/l9v4x9ia0hnf4v9piwece23hrcjoikf8", {
            method: "POST",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({
              location: "confirmation_page",
              orderId: orderId,
              shopifyCustomerId: shopifyCustomerId,
              email: email,
              testId: test.e,
              variantId: test.v,
            }),
          });
        });
      }
    }
  }, [orderId, shopifyCustomerId, email]);

  // push to data layer
  useEffect(() => {
    if (orderInfo.handle === "") {
      return;
    }
    window.dataLayer = window.dataLayer ?? []; // fallback
    let productHandle = "membership";
    switch (orderInfo.handle) {
      case "rotm_standard":
        productHandle = "rum-box-membership";
        break;
      case "gotm_taster":
        productHandle = "gin-sip-box";
        break;
      case "rotm_taster":
        productHandle = "rum-taster-box";
        break;
    }

    fetch(`https://club.craftginclub.co.uk/products/${productHandle}.js`)
      .then((response) => response.json())
      .then((data) => {
        const eventDetail = {
          email_sha256: emailSha,
          order: {
            order_id: orderId,
            discount: {
              code: discountCode,
              amount: totalDiscount,
            },
            total_price: totalPrice,
            subtotal_price: totalPrice,
            total_tax: 0,
            shipping: 0,
            line_items: [
              {
                product_id: data.id,
                variant_id: data.id,
                sku: sku,
                title: data.title,
                category: "frequency_" + frequency,
                price: data.price / 100,
                quantity: 1,
              },
            ],
          },
        };

        window.dataLayer = window.dataLayer ?? [];

        // TODO: Confirm if needed after analytics rework
        window.dataLayer.push({
          event: "cgc_order_confirmation",
          ...eventDetail,
        });

        // TODO: Confirm if needed after analytics rework
        if (orderInfo.handle === "rotm_standard") {
          window.dataLayer.push({
            event: "new_rum_subscription_confirmed",
            ...eventDetail,
          });
        }

        sendGTMEvent({
          email_sha256: emailSha,
        });

        sendGTMEvent({
          event: "subscription_checkout_completed",
          ecommerce: {
            transaction_id: orderId,
            currency: "GBP", // TODO: Confirm once EUR is supported
            value: totalPrice,
            items: [
              {
                item_id: sku,
                item_name: data.title,
                index: 0,
                item_brand: "Craft Gin Club",
                price: totalPrice,
                item_quantity: 1,
              },
            ],
          },
        });
      });
  }, [orderInfo]);

  useEffect(() => {
    if (!orderId || !window.ire) {
      return;
    }

    // track to ire
    window.ire("trackConversion", 12805, {
      orderId: orderId,
      orderPromoCode: discountCode,
      items: [
        {
          subTotal: totalPrice,
          category: frequencyString,
          sku: sku,
          quantity: 1,
        },
      ],
    });
  }, [discountCode, frequencyString, orderId, sku, totalPrice]);

  // Pinterest
  useEffect(() => {
    if (!totalPrice || !frequencyString || !window.pintrk) {
      return;
    }

    try {
      window.pintrk("track", "checkout", {
        value: totalPrice,
        order_quantity: 1,
        currency: "GBP",
        frequency: frequencyString,
      });
    } catch (err) {
      /* empty */
    }
  }, [frequencyString, totalPrice]);

  useEffect(() => {
    try {
      ~(function TP() {
        if (!email || !window.TriplePixel) {
          setTimeout(TP, 400);

          return;
        }
        window.TriplePixel("Contact", { email: email });
      })();
    } catch (error) {
      console.error("TriplePixel error", error);
    }
  }, [email]);

  return (
    <>
      <Container id={id} className="py-0">
        {isWelcomeBox && (
          <Paragraph>
            Your first box will be with you soon. A tracking code will be
            emailed to you once your box has been dispatched. So just sit back,
            relax and wait for your treats to arrive!
          </Paragraph>
        )}
        {isTasterBox && (
          <Paragraph as={"div"}>
            Your <b>Taster Box</b> will be with you soon. A tracking code will
            be emailed to you once your box has been dispatched. So just sit
            back, relax and wait for your treats to arrive!
          </Paragraph>
        )}
        {!isTasterBox && !isWelcomeBox && deliveryDate && boxTitle && (
          <div>
            <Paragraph>
              Your first {productTitle} will be <strong>{boxTitle}</strong>
            </Paragraph>
            <Paragraph>
              and your parcel is due to arrive <strong>{deliveryDate}</strong>.
            </Paragraph>
          </div>
        )}
        {isFreeGin && (
          <Paragraph>
            You signed up using our <b>free gin code</b>. We will be shipping
            out a free mystery bottle of amazing craft gin to everyone who signs
            up using that code - keep an eye on your emails!
          </Paragraph>
        )}
      </Container>
      {orderId && hasLoaded && (
        <MentionMe
          firstName={firstName}
          lastName={lastName}
          email={email}
          shopifyCustomerId={shopifyCustomerId}
          orderId={orderId}
          totalPrice={totalPrice}
          discountCode={discountCode}
          spirit={spirit}
        />
      )}
      {emailSha && (
        <RoktSnippet
          id="2934049248478772232_ea42b38f1d184dcd85089f624eb3c3b0"
          onLoaded={() => {
            window["rokt"]?.setAttributes({ emailsha256: emailSha || "" });
          }}
        />
      )}
    </>
  );
};

export default MembershipConfirmationInfo;
